/*
    Only the global classes can have _
*/
@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-alpine.css';

/* ! Reset and Set Global properties */
body {
    display: flex;
	width: 100%;
	height: 100%;
    background-color: var(--color-bg-primary);
}
* {
    margin: 0;
    padding: 0;
    font-family: var(--primary-font);
    // font-weight: var(--font-regular);
    color: var(--color-fg-tertiary);
    transition:var(--transition-bg-color);
}
input {
    font-size: var(--font-b1);
    color: var(--color-fg-primary);
    font-weight: var(--font-regular);
}
input:focus {
    color: var(--color-fg-primary);
    font-weight: var(--font-regular);
}
input::placeholder {
    color: var(--color-fg-quaternary);
    font-weight: var(--font-regular);
}
a {
    text-decoration: none;
}
p {
    transition: all .5s ease-in-out;
	margin-bottom: 0rem;
}
button {
    border: none;
}
::marker {
    color: transparent;
}

body::-webkit-scrollbar {
    width: 0;
}
/* * Screen width */
.w-100 {
    width: 100%;
    background-color: var(--color-bg-secondary);
}


/* ? HELPERS -------------------> */
.cursor_pointer {
    cursor: pointer;
}
.none {
    display: none;
}
.d_flex {
    display: flex;
}
.pointer_events_none {
    pointer-events: none;
}
/* * Opacity Transition */
.opacity_ {
    opacity:0;
    transition: opacity .5s ease-in-out;
}


/* ? FONTS -------------------> */
/* BIG HEADLINES */
.font_bh1 {
    font-size: var(--font-bh1);
}
.font_bh2 {
    font-size: var(--font-bh2);
}
.font_bh3 {
    font-size: var(--font-bh3);
}
/* HEADLINES */
.font_h1 {
    font-size: var(--font-h1);
}
.font_h2 {
    font-size: var(--font-h2);
}
.font_h3 {
    font-size: var(--font-h3);
}
/* BODY */
.font_b1 {
    font-size: var(--font-b1);
}
/* CAPTIONS */
.font_c1 {
    font-size: var(--font-c1);
}
.font_c2 {
    font-size: var(--font-c2);
}
.font_c3 {
    font-size: var(--font-c3);
}
.font_label {
    font-size: var(--font-b1);
}
/* * Font Weight */
.font_light {
    font-weight: var(--font-light);
}
.font_regular {
    font-weight: var(--font-regular);
}
.font_medium {
    font-weight: var(--font-medium);
}
.font_semibold {
    font-weight: var(--font-semibold);
}
.font_bold {
    font-weight: var(--font-bold);
}
.font_extrabold {
    font-weight: var(--font-extrabold);
}


/* ? COLORS -----------------------------------> */
/* * Color font */
.color_bg_primary {
    color: var(--color-bg-primary);
}
/* * Color font */
.color_fg_primary {
    color: var(--color-fg-primary);
}
.color_fg_secondary {
    color: var(--color-fg-secondary);
}
.color_fg_tertiary {
    color: var(--color-fg-tertiary);
}
.color_fg_quaternary {
    color: var(--color-fg-quaternary);
}
.color_fg_link {
    color: var(--color-fg-link);
}
.color_fg_danger {
    color: var(--color-fg-danger);
}
.color_fg_danger {
    color: var(--color-fg-danger);
}
.color_fg_link {
    color: var(--color-fg-link);
}
.color_link {
    color: var(--color-fg-brand);
    text-decoration: none;
    cursor: pointer;
}
.color_link:hover {
    text-decoration: underline;
    color: var(--color-btn-link-hover);
    text-decoration-color: var(--color-btn-link-hover);
    text-underline-offset: .25rem
}
.color_link:active {
    text-decoration: underline;
    color: var(--color-btn-link-active);
    text-decoration-color: var(--color-btn-link-active);
    text-underline-offset: .25rem
}


/* ? BUTTONS -----------------------------> */
/* * BUTTON PRIMARY */
.btn_primary {
    padding: .6875rem var(--spacing-4);
    background-color: var(--color-btn-primary-enabled);
    border-radius: var(--radius-md);
    display: flex;
    gap: .5rem;
    align-items: center;
    color: var(--color-fg-primary-inverted);
    font-size: var(--font-b1);
    cursor: pointer;
    justify-content: center;
    font-weight: var(--font-regular);
    max-height: 2.875rem;
    border: .0625rem solid transparent;
}
/* BUTTON IMG */
.btn_primary img {
    width: var(--size-icon-xs);
}
/* HOVER */
.btn_primary:hover {
    background-color: var(--color-btn-primary-hover);
}
/* ACTIVE */
.btn_primary:active {
    background-color: var(--color-btn-primary-active);
}
/* FOCUS VISIBLE */
.btn_primary:focus-visible {
    background-color: var(--color-btn-primary-enabled);
    box-shadow: var(--box-shadow-btn-primary);
    outline: none;
}
/* * BUTTON DISABLED PRIMARY */
.btn_disabled_primary {
    pointer-events: none;
    background-color: var(--color-bg-disabled);
    color: var(--color-fg-disabled);
}
/* * BUTTON SECONDARY */
.btn_secondary {
    padding: .6875rem var(--spacing-4);
    background-color: var(--color-bg-primary);
    border-radius: var(--radius-md);
    display: flex;
    gap: .5rem;
    align-items: center;
    color: var(--color-fg-secondary);
    font-size: var(--font-b1);
    cursor: pointer;
    justify-content: center;
    font-weight: var(--font-regular);
    max-height: 2.875rem;
    outline: .0625rem solid var(--color-border-enabled);
    border: .0625rem solid transparent;
}
/* BUTTON IMG */
.btn_secondary img {
    width: var(--size-icon-sm);
}
/* HOVER */
.btn_secondary:hover {
    outline: .0625rem solid var(--color-border-hover);
    background-color: var(--color-btn-secondary-hover);
}
/* ACTIVE */
.btn_secondary:active {
    outline: .0625rem solid var(--color-border-active);
    background-color: var(--color-btn-secondary-active);
}
/* FOCUS VISIBLE */
.btn_secondary:focus-visible {
    background-color: var(--color-bg-primary);
    box-shadow: var(--box-shadow-btn-secondary);
    outline: .0625rem solid var(--color-border-enabled);
}
/* * BUTTON DISABLED SECONDARY */
.btn_disabled_secondary {
    outline: .0625rem solid var(--color-border-disabled);
    pointer-events: none;
    background-color: var(--color-bg-disabled);
    color: var(--color-fg-disabled);
}


/* ? INPUTS -----------------------------> */
/* * INPUT CONTAINER */
.input_cont {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
/* INPUT FIRST IMG */
.input_cont img:first-child {
    position: absolute;
    left: .625rem;
    width: var(--size-icon-xs);
}
/* INPUT LAST IMG  */
.input_cont img:last-child {
    position: absolute;
    right: .625rem;
    width: var(--size-icon-xs);
    cursor: pointer;
}
/* * INPUT PRIMARY */
.input_primary {
    border: .0625rem solid transparent;
    outline:.0625rem solid var(--color-border-enabled);
    background-color: var(--color-bg-primary);
    color: var(--color-fg-primary);
    padding: .625rem 1rem;
    border-radius: var(--radius-md);
    font-size: var(--font-b1);
    padding-inline: 1.25rem;
    /* * Evita que el padding se desborde */
    width: -webkit-fill-available;
    transition:background-color .2s ease-in-out;
}
/* FOCUS VISIBLE */
.input_primary:focus-visible {
    outline:.0625rem solid var(--color-border-brand);
    box-shadow: var(--box-shadow-primary);
}
/* PLACEHOLDER */
.input_primary::placeholder {
    color: var(--color-fg-quaternary);
}
/* HOVER */
.input_primary:hover {
    outline:.0625rem solid var(--color-border-hover);
}
/* * INPUT ERROR */
.input_error {
    outline:.0625rem solid var(--color-fg-danger);
}
/* FOCUS VISIBLE */
.input_error:focus-visible {
    outline:.0625rem solid var(--color-system-error-200);
    box-shadow: var(--box-shadow-danger);
}
/* HOVER */
.input_error:hover {
    outline:.0625rem solid var(--color-fg-danger);
}
/* ! ERROR MSN ------------------------------> */
.input_error_msn {
    position: absolute;
    bottom: calc(-.875rem - var(--spacing-2));
    color: var(--color-fg-danger);
}


.icon-xs {
    width: var(--size-icon-xs);
    height: var(--size-icon-xs);
}
/* ! SKEPETON SIZE */
.skeleton_fade {
    background: linear-gradient(45deg, var(--color-border-enabled) ,var(--color-divider) , var(--color-border-hover));
    background-size: 400%;
    background-position: 0%;
    animation: skeletonFadeIn 2s linear infinite;
    animation-timing-function: ease-in-out;
    border-radius: var(--radius-md);
}
@keyframes skeletonFadeIn {
    0% {
        background-position: 100%;
    }
    50% {
        background-position: 0%;
    }
    100% {
        background-position: 100%;
    }
}
/* * Responsive HEIGHT */
@media screen and (min-height:900px) {
    html {
        font-size: 14px;
    }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

::ng-deep .mdc-form-field .mdc-switch--checked .mdc-switch__track {
	background-color: #FF5722 !important; // Cambia este color por el que prefieras
  }

  ::ng-deep .mdc-form-field .mdc-switch--checked .mdc-switch__handle {
	background-color: #FFCCBC !important; // Cambia este color por el que prefieras
  }

  ::ng-deep .mdc-form-field .mdc-switch__icon--on path {
	fill: #FF5722 !important; // Cambia este color por el que prefieras
  }

  ::ng-deep .mdc-form-field .mdc-switch__icon--off path {
	fill: #FFCCBC !important; // Cambia este color por el que prefieras
  }
