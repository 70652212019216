* {
    //
    // ?                            Local Styles
    //
    // * -------------------------- Text Styles -------------------------------------
    --font--primary: 'Inter', sans-serif;
	--font--secondary: 'Work Sans', sans-serif;
    --font-code: 'DM Mono', sans-serif;
    // * -------------------------- Big Headlines --------------------
	--big-headlines:'Work Sans', sans-serif;
    --font-bh1: 5rem;
    --font-lh-bh1: 5.5rem;

    --font-bh2: 4rem;
    --font-lh-bh2: 4.25rem;

    --font-bh3: 3rem;
    --font-lh-bh3: 3.25rem;

    --font-bh4: 2.25rem;
    --font-lh-bh4: 2.75rem;

    --font-bh5: 2.125rem;
    --font-lh-bh5: 2.625rem;

    --font-bh6: 1.875rem;
    --font-lh-bh6: 2.125rem;

    --font-spacing-bh: -1%;
    // * -------------------------- Headlines --------------------------
	--headlines: 'Work Sans', sans-serif;

    --font-h1: 1.5rem;
    --font-lh-h1: 1.75rem;

    --font-h2: 1.375rem;
    --font-lh-h2: 1.625rem;

    --font-h3: 1.25rem;
    --font-lh-h3: 1.5rem;

    --font-h4: 1.125rem;
    --font-lh-h4: 1.375rem;

    --font-spacing-h: -0.8%;
    // * -------------------------- Body -----------------------------
	--body:'Inter', sans-serif;
    --font-b1: 1rem;
    --font-lh-b1: 1.5rem;

    --font-b2: 0.875rem;
    --font-lh-b2: 1.375rem;

    --font-spacing-b: -0.6%;
    // * -------------------------- Captions -----------------------------
	--captions: 'Inter', sans-serif;
    --font-c1: 0.75rem;
    --font-lh-c1: 1.25rem;

    --font-c2: 0.625rem;
    --font-lh-c2: 1.125rem;

    --font-spacing-c: -0.4%;
    //
    // ?                            Helpers
    //
    // * -------------------------- Containers --------------------------
    --dashboard--width: 82.45%;
    --sidebar--width: 18rem;
    --auth__form--width: 36rem;
    // * -------------------------- Transitions ---------------------------------------------------
    --transition--bg-color: background-color 0.2s ease-in-out;


	//* font weight
	--black: 900;
	--extrabold: 800;

	--semibold:600;
	--medium: 500;
	--regular: 400;
}
